.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.article {
  padding-top: 90px;
}
.article .pagination {
  text-align: center;
  margin: 61px 0 80px 0;
}
.article /deep/ .el-pagination.is-background .el-pager li:hover {
  color: #ff5f23;
}
.article /deep/ .el-pagination.is-background .el-pager li.active {
  background: #ff6600;
  color: #fff;
}
.article /deep/ button {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
}
.article /deep/ .el-pager .number {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
  line-height: 2.083333333333333vw;
  font-size: 0.9375vw;
}
.article /deep/ .el-icon {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
  line-height: 2.083333333333333vw;
}
@media screen and (min-width: 1440px) {
  .pagination /deep/ button {
    width: 40px;
    height: 40px;
  }
  .pagination /deep/ .el-pager .number {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
  .pagination /deep/ .el-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}
