.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.news_banner {
  box-sizing: border-box;
  height: 35.41666666666667vw;
  padding-top: 9.427083333333334vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16352393481679355.png);
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  text-align: center;
}
.news_banner .title {
  margin: 0 auto;
  font-size: 4.166666666666666vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.news_banner .desc {
  font-size: 1.4583333333333333vw;
  margin-top: 1.4583333333333333vw;
  line-height: 2.083333333333333vw;
  letter-spacing: 0.052083333333333336vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.news_banner .icon {
  margin-top: 3.6979166666666665vw;
  display: flex;
  justify-content: center;
}
.news_banner .icon .ks,
.news_banner .icon .wx,
.news_banner .icon .wb {
  width: 1.875vw;
  height: 1.875vw;
  position: relative;
}
.news_banner .icon .ks .icon_img,
.news_banner .icon .wx .icon_img,
.news_banner .icon .wb .icon_img {
  width: 100%;
  height: 100%;
}
.news_banner .icon .ks_photo {
  position: absolute;
  width: 7.291666666666667vw;
  height: 7.291666666666667vw;
  left: -2.7083333333333335vw;
}
.news_banner .icon .ks_photo img {
  width: 100%;
  height: 100%;
}
.news_banner .icon .wx {
  margin-left: 1.7708333333333333vw;
  margin-right: 1.7708333333333333vw;
}
