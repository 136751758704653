.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.report-body {
  padding-left: 12.5vw;
  padding-right: 12.5vw;
}
.report-body .report-list {
  display: flex;
  flex-wrap: wrap;
  transition: all 0.5;
  width: 100%;
  box-sizing: border-box;
}
.report-body .report-list .report {
  margin-top: 4.166666666666666vw;
  margin-right: 2.083333333333333vw;
}
.report-body .report-list .mrNone {
  margin-right: 0vw;
}
.report-body .report:hover .news_contaniner {
  transform: translateY(-10px);
  box-shadow: 0px 2px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
}
.report-body .title:hover {
  color: #2e6fed !important;
}
.report-body .news_contaniner {
  width: 22.916666666666664vw;
  height: 20.833333333333336vw;
  border-radius: 0.8333333333333334vw;
  box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  overflow: hidden;
}
.report-body .news_contaniner .img-wrap {
  width: 22.916666666666664vw;
  height: 13.177083333333334vw;
  position: relative;
}
.report-body .news_contaniner .img-wrap .thumb_img {
  width: 100%;
  height: 13.177083333333334vw;
}
.report-body .news_contaniner .img-wrap .thumb_img img {
  display: block;
  width: 100%;
  height: 13.177083333333334vw;
  object-fit: cover;
}
.report-body .news_contaniner .img-wrap .label_box {
  position: absolute;
  left: 1.5625vw;
  bottom: 0.6770833333333334vw;
  display: flex;
  align-items: center;
}
.report-body .news_contaniner .img-wrap .info {
  color: #ffffff;
  font-size: 0.7291666666666666vw;
}
.report-body .news_contaniner .img-wrap .icon {
  margin-right: 5px;
}
.report-body .news_contaniner .img-wrap .icon img {
  width: 0.625vw;
  display: block;
  height: 0.7291666666666666vw;
}
.report-body .news_contaniner .word-wrap {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding-top: 1.3020833333333335vw;
  padding-left: 1.5625vw;
  padding-right: 1.5625vw;
  padding-bottom: 1.3020833333333335vw;
}
.report-body .news_contaniner .word-wrap .title {
  width: 19.0625vw;
  font-size: 1.25vw;
  line-height: 1.7187500000000002vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.report-body .news_contaniner .word-wrap .desc {
  width: 20.3125vw;
  font-size: 0.8333333333333334vw;
  line-height: 1.5;
  margin-top: 0.5208333333333333vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
a {
  text-decoration: none;
}
 /deep/ a {
  text-decoration: none;
  color: #666666;
}
